@charset "utf-8";

@use "config";

// Base Page Size ****************************************
@mixin pagesize {
  margin: auto;
  max-width: config.$pg-width;
  min-width: config.$min-width;
  position: relative;
  text-align: left;
  width: 98%;
}

// Button Base ****************************************
@mixin butnbase($bgcolor: config.$base-bkg, $color: config.$title-color) {
  background: $bgcolor;
  border: darken($bgcolor, 3%) 1px solid;
  border-radius: 2px;
  box-shadow:rgba(0, 0, 0, .3) 0 1px 1px, rgba(#fff, .1) 1px 1px 1px inset;
  box-sizing: border-box;
  color: $color;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-weight: normal;
  line-height: 1.15;
  outline: none;
  overflow: hidden;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  text-shadow: -1px -1px 0 rgba(#000, .1);
  vertical-align: bottom;
  min-width: 120px;
  zoom: 1;


  &:hover {
    background: darken(config.$base-bkg, 8%);
    color: darken($color, 14%);
    text-decoration: none;
  }

  &:disabled,
  &.disabled {
    background: darken($bgcolor, 8%);
    border: darken($bgcolor, 16%) 1px solid;
    box-shadow:rgba(0, 0, 0, .1) 0 0 2px, rgba(0, 0, 0, .2) 1px 1px 2px inset;
    color: darken($color, 14%);
    position: relative;
    top: 1px;
    text-shadow: none;
  }

  &:disabled,
  &.disabled {
    cursor: default;
  }

  &::-moz-focus-inner {
    border: 0px;
  }

  i {
    margin-right: 4px;
  }

  &.mini {
    min-width: 0;
    padding: 8px 16px;
  }
}

// Clearfix ****************************************
@mixin clearfix {
  *zoom: 1;

  &:after {
    content: ".";
    display: block;
    height: 0;
    font-size: 0;
    clear: both;
    visibility: hidden;
  }
}

// Media query ****************************************
@mixin mq-up($breakpoint: md) {
  @media #{map-get(config.$breakpoint-up, $breakpoint)} {
    @content;
  }
}

@mixin mq-down($breakpoint: md) {
  @media #{map-get(config.$breakpoint-down, $breakpoint)} {
    @content;
  }
}

// Paragraph Button Style ****************************************
@mixin basic-btnstyle {
  margin: 32px 0;
  text-align: center;
}
