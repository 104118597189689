@charset "utf-8";

@use "config";
@use "mixin";

//小規模なパーツ群の集まり
// Tables ****************************************
.tbl-base {
  clear: both;
  border: 1px solid config.$border-color;
  margin: 16px 0;
  width: 100%;
  background: #fff;
  word-wrap: break-word;

  th,
  td {
    border: 1px solid config.$border-color;
    font-size: config.$font-base;
    padding: 8px;
    vertical-align: middle;
    word-wrap: break-word;
    word-break: break-all;
  }

  td {
    vertical-align: middle;

    &.layout01 {
      width: 65%;

      @include mixin.mq-down() {
        width: auto;
      }
    }
  }
}

.tbl-noborder {
  border: none !important;
  th,
  td {
    border: none !important;
  }
}

// txt ****************************************
.link-txtr {
  text-align: right;
}

// btn ****************************************
.btn-basic {
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: config.$font-base;
  line-height: 1.5;
  margin-bottom: 8px;
  padding: 0.5em 1em;
  text-decoration: none;
  transition: .4s;

  &:hover {
    opacity: .7;
  }
}

.btn-base {
  background: #fff;
  border: solid 1px #333;
  font-size: config.$font-base;
  @include mixin.butnbase;

  @include mixin.mq-down() {
    width: 80%;
  }

  &:hover {
    background: rgba(224, 224, 224, .7);
    /*opacity: .7;*/
  }

  a {
    color: #333;
    text-decoration: none;

    &:hover {
      color: #fff;
      opacity: inherit;
    }
  }

  [class^="icon-"],
  [class*=" icon-"] {
    padding: 0 8px 0 0;
  }

}

.btn-green {
  @include mixin.butnbase;
  background: config.$button-bkg-color;
  border: 1px solid config.$button-border-color;
  color: #fff;

  &:hover {
    background: darken(config.$button-bkg-color, 8%);
    color: #fff;
    text-decoration: none;
  }
}

.members .btn-basic {
  @include mixin.butnbase;
  background: #fff;
  padding: 8px;
}

.sp-gnav .members .btn-basic {
  width: 180px;
}

//grand top ****************************************
.news-txt {
  font-size: config.$font-reset;

  ul {
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid config.$border-color;
      display: flex;
      margin-bottom: 8px;
      padding: 8px 0;

      @include mixin.mq-down() {
        display: block;
        margin-bottom: 0;
        padding: 8px;
      }

      span {
        display: block;
        font-size: 0.9em;
        margin: 0 8px 8px 0;
        width: 20%;

        @include mixin.mq-down() {
          float: none;
          width: auto;
        }
      }

      a {
        display: inline-block;
        margin: 0;
        width: 80%;

        @include mixin.mq-down() {
          padding: 0 0 10px 0;
          width: 100%;
        }
      }
    }
  }
}

.lst-page {
  .event-name {
    font-size: 1.05em;
  }
}


.btn-common-space {
  margin: 32px 0;
}

//layout top ****************************************
.flex-r {
  display: flex;
  justify-content: flex-end;

  @include mixin.mq-down() {
    justify-content: center;
  }
}
