@charset "utf-8";

@use "config";
@use "mixin";

//最も主となる要素

html {
  height: 100%;
  min-width: config.$min-width;
  overflow-y: scroll;
  width: 100%;

  @include mixin.mq-down() {
    min-width: auto;
  }
}

@media screen and (min-width : 768px) {
  html {
    font-size: 87.5%;
  }
}

@media screen and (min-width : 1024px) {
  html {
    font-size: 100%;
  }
}

body {
  color: config.$font-color;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  font-size: 100%;
  letter-spacing: config.$font-spacing;
  line-height: config.$s-ratio;
  min-height: 100vh;
  display: flex;
  overflow-x: hidden;
  word-wrap: break-word;
}

// Link Base Style *****************************
a,
.linkstyle {
  transition: linear .3s;
  color: config.$link-color;
  cursor: pointer;
  text-decoration: underline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;

  &:hover {
    outline: none;
    text-decoration: none;

  }

  &.disabled {
    pointer-events: none;
  }
}

strong {
  font-weight: bold;
}

form {
  display: inline;
}

.linkstyle-bold {
  font-weight: bold;
}

// H Line **********************************
hr {
  border: none;
  border-bottom: 1px solid config.$border-color;
  margin: 16px 0;

  &.low {
    margin: 8px 0;
  }

  &.medium-low {
    margin: 24px 0;
  }

  &.middle {
    margin: 32px 0;
  }

  &.middle-high {
    margin: 40px 0;
  }

  &.high {
    margin: 64px 0;
  }
}

// Ul tag Under Navigation *************************************
nav ul {
  list-style: none;
  margin: 0;
  padding: 0 0 0 8px;
}

// image filse size ******************************************
img {
  max-width: 100%;
}

// Heading Style *********************************************
h1 {
  font-size: config.$font-base;
  margin: 0;
  display: table;
}

h2 {
  font-size: config.$font-large;
  font-weight: normal;
  margin: 32px 0 16px;
}

h3 {
  font-size: config.$font-middle;
  margin: 16px 0;
}

h4 {
  font-size: 1em;
  margin: 8px 0;

  &.ttl-box {
    font-size: config.$font-middle;
  }
}

h5 {
  font-size: config.$font-base;
  margin: 8px 0;

  &.font-small {
    color: #666;
    font-size: 1.2em;
    font-weight: normal;
    margin: 32px 0 16px;
  }
}

h6 {
  font-size: config.$font-small;
  margin: 8px 0;
}

// Paragraph  *************************************
p {
  margin: 8px 0;
}

// List Style **********************************************
ul,
ol {
  font-size: config.$font-base;
  margin: 16px 0 0 0;
  padding: 0;
  padding-left: 1.5em;

  li {
    list-style-type: none;
  }

  &.style-none {
    padding-left: 0;

    li {
      list-style-type: none;
    }
  }
}

// Data List **********************************************
dl {
  margin: 16px 0;
}

dt {
  margin: 8px 0;
}

dd {
  margin: 8px 0 8px 16px;
}
