// Web Common Style Config
@charset "utf-8";

// For Android Browser
$prefixList: -webkit-,
null;

// Color Base
$base-bkg: #f4f4f4;
$sub-bkg: #e3e3e3;
$font-color: #666;
$title-color: #666;
$button-bkg-color: #8a9b0f;
$button-border-color: #7e8d0e;
$button-font-color: #fff;
$border-color: #cfcfcf;

$nav-bkg: #f4f4f4;
$nav-color: #666;
$label-bkg: #444;
$label-color: #fff;

$point-color: #8a9b0f;

$link-color: #404040;
$link-hover: #f28705;

$iconf-red:#d7003a;
$iconf-green:#8a9b0f;
$iconf-blue: #274a78;
$iconf-orange: #ee7800;
$iconf-white: #fff;
$iconf-gray: #666;
$iconf-black: #000;

// Nomal Massage
$msg-color: #274a78;
$msg-bkg: #eaf4fc;

// NOTE
$note-msg-color: #ee7800;
$note-msg-bkg: #fff1cf;

// WARNING
$warn-msg-color: #d7003a;
$warn-msg-bkg: #fdeff2;

// Ratio
$g-ratio: 1.62;
$s-ratio: 1.8;

//Font space
$font-spacing: 0.12;


// Font Size
$font-xlarge: 1.6em;
$font-large: 1.4em;
$font-middle: 1.2em;
$font-reset: 1em;
$font-base: .92em;
$font-small: .82em;
$font-minimum: .75em;

// Page Size
$full: 100%;
$pg-width: 1200px;
$min-width: 768px;
$min-height: 600px;

// media query
// min-width
$breakpoint-up: ('sm': 'screen and (min-width: 480px)',
	'md': 'screen and (min-width: 769px)',
	'lg': 'screen and (min-width: 1024px)',
	'xl': 'screen and (min-width: 1200px)',
) !default;

// max-width
$breakpoint-down: ('sm': 'screen and (max-width: 479px)',
	'md': 'screen and (max-width: 768px)',
	'lg': 'screen and (max-width: 1023px)',
	'xl': 'screen and (max-width: 1199px)',
) !default;

// space
$common-box-large-space : 16px;
$common-box-base-space : 8px;

// width
$common-width : 80%;
$common-max-width : 1280px;

// navigation
$sp-navi-background :rgba(0, 0, 0, 0.8);
