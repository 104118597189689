@charset "utf-8";

@use "config";
@use "mixin";

//最も小規模なパーツ群

// font *********************************************
.f-bold {
  font-weight: bold;
}

.f-normal {
  font-weight: normal;
}

.f-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.txt-left {
  text-align: left;
}

.txt-center {
  text-align: center;
}

.iconf-r {
  color: config.$iconf-red;
}

.iconf-b {
  color: config.$iconf-blue;
}

.iconf-g {
  color: config.$iconf-green;
}

.iconf-o {
  color: config.$iconf-orange;
}

.iconf-w {
  color: config.$iconf-white;
}

.iconf-g {
  color: config.$iconf-gray;
}

.iconf-bk {
  color: config.$iconf-black;
}

.icon-file-pdf-m:before,
.icon-file-pdf-m1:before {
  font-size: 34px;
}

// Button *********************************************
// クリーン
.cleanbtn {
  @include mixin.butnbase;
}

// 登録
.submitbtn {
  @include mixin.butnbase(config.$point-color, #fff);
}

// 編集
.editbtn {
  @include mixin.butnbase(config.$msg-color, #fff);
}

// 削除
.deleatbtn {
  @include mixin.butnbase(config.$warn-msg-color, #fff);
}

// ログイン
.memberbtn {
  @include mixin.butnbase(#333, #fff);
}

// グランドトップ言語切り替え(SP) *********************************************

.sp-lang-switch {
  display: flex;
  justify-content: flex-end;
  margin: 16px;

  @include mixin.mq-up() {
    display: none;
  }

  li {
    &.active span {
      background: #666;
      color: #fff;
      pointer-events: none;
    }

    .switch-btn {
      background: #fff;
      border-radius: 2px;
      display: inline-block;

      a {
        cursor: pointer;
        display: block;
        padding: 4px 20px;
        text-decoration: none;
      }
    }
  }

  a {
    color: inherit;
  }
}

// Heading Style *********************************************
.h-border {
  border-bottom: 2px solid #ccc;
  margin-bottom: 8px;
}

.h-mgn-none {
  margin: 0;
}

.h-news {
  margin-top: 32px;

  @include mixin.mq-down() {
    margin-top: 0;
  }
}

// iframe  *********************************************
.gtop-news {
  border: none;
  min-height: 230px;
  width: 100%;
}

// Margin Size *******************************

.mg0 {
  margin: 0 !important;
}

.mg-b0 {
  margin-bottom: 0 !important;
}

.mg-t0 {
  margin-top: 0 !important;
}

.mg-r0 {
  margin-right: 0 !important;
}

.mg-l0 {
  margin-left: 0 !important;
}

.mg4 {
  margin: 4px !important;
}

.mg8 {
  margin: 8px !important;
}

.mg16 {
  margin: 16px !important;
}

.mg-t4 {
  margin-top: 4px !important;
}

.mg-t8 {
  margin-top: 8px !important;
}

.mg-t16 {
  margin-top: 16px !important;
}

$margin-v-num: 4,
8,
16,
32;

@each $margin-v in $margin-v-num {
  .mgv#{$margin-v} {
    margin-top: #{$margin-v}px;
    margin-bottom: #{$margin-v}px;
  }
}

// Padding Size *****************************
.pd0 {
  padding: 0 !important;
}

.pd-b0 {
  padding-bottom: 0 !important;
}

.pd-t0 {
  padding-top: 0 !important;
}

.pd-r0 {
  padding-right: 0 !important;
}

.pd-l0 {
  padding-left: 0 !important;
}

.pdv0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pdh0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pd-l4 {
  padding-left: 4px !important;
}

.pd-l8 {
  padding-left: 8px !important;
}

$padding-all-num: 4,
8,
16;

@each $padding-all in $padding-all-num {
  .pd#{$padding-all} {
    padding: #{$padding-all}px !important;
  }
}
