@charset "utf-8";

@use "config";
@use "mixin";

//構成

//** Global Header **************************
header {
  background: config.$nav-bkg;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .3);
  color: config.$nav-color;
  display: flex;
  min-height: 50px;
  padding: 8px 0;

  @include mixin.mq-down() {
    align-items: center;
    box-sizing: border-box;
    min-height: 66px;
    position: fixed;
    width: 100%;
    z-index: 1;
  }

  .sp-gnav {
    display: none;

  }
}

#nav_toggle {
  display: none;
}

.glblhead {
  align-items: center;
  display: flex;
  margin: 0 auto;
  width: config.$common-width;
  max-width: config.$common-max-width;

  @include mixin.mq-down() {
    width: inherit;
  }

  h1 {
    align-items: center;
    display: flex;
    margin-right: auto;

    @include mixin.mq-down() {
      float: left;
      margin-left: 8px;
      width: 75%;
    }

    a {
      align-items: center;
      display: flex;
      justify-content: center;
      text-decoration: none;

      .logo-img {
        min-width: 50px;
        margin-right: 16px;
      }

      img {
        margin: 0;
        padding: 0;
        width: auto;
        height: 50px;
        vertical-align: middle;
      }
    }

    .name {
      @include mixin.mq-down() {}
    }
  }

  .official-name {
    display: block;
    float: left;
  }

  .lang-switch {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
    min-width: 25%;

    @include mixin.mq-down() {
      display: none;
    }

    li {
      text-align: right;

      &.active span {
        background: #666;
        color: #fff;
        pointer-events: none;
      }

      .switch-btn {
        background: #fff;
        border: 1px solid #bababa;
        border-radius: 2px;
        cursor: pointer;
        display: inline-block;
        padding: 0 18px;
        text-decoration: none;

        &:hover {
          background: #666;
          color: #fff;
          transition: all 0.1s ease-in;
        }

        a {
          text-decoration: none;
        }
      }

      &+li {
        padding: 0 16px;
      }

      &+li:last-child {
        padding-right: 0;

        @include mixin.mq-down() {
          padding-right: 20px;
        }
      }
    }

    a {
      color: inherit;
    }
  }
}

.p-fixed {
  position: fixed;
}

/*for SP open close button*/
@include mixin.mq-down() {
  #nav_toggle {
    cursor: pointer;
    /*display: flex;*/
    height: 27px;
    display: block;
    margin-left: auto;
    position: relative;
    right: 16px;
    /*top: 12px;*/
    width: 40px;
    z-index: 100;

    span {
      background: #666;
      display: block;
      height: 3px;
      left: 0;
      position: absolute;
      transition: 0.5s ease-in-out;
      width: 100%;

      &:nth-child(1) {
        top: 0px;
      }

      &:nth-child(2) {
        top: 12px;
      }

      &:nth-child(3) {
        top: 24px;
      }
    }
  }

  /*開閉ボタンopen時*/
  .open #nav_toggle span {
    &:nth-child(1) {
      top: 12px;
      transform: rotate(135deg);
    }

    &:nth-child(2) {
      left: 50%;
      width: 0;
    }

    &:nth-child(3) {
      top: 12px;
      transform: rotate(-135deg);
    }
  }
}

//** breadcrumb  **********************
.breadcrumb {
  overflow: hidden;
  *zoom: 1;
  box-sizing: border-box;
  margin: 12px auto;
  padding-left: 0;
  width: 100%;

  @include mixin.mq-down() {
    margin-top: 0;
    padding: 8px;
  }

  &.pc-breadcrumb {
    @include mixin.mq-down() {
      display: none;
    }
  }

  &.sp-breadcrumb {
    @include mixin.mq-up() {
      display: none;
    }
  }

  li {
    float: left;
    font-size: config.$font-small;
    margin: 0;
    padding: 0;

    @include mixin.mq-down() {
      line-height: 1.5;
    }

    &:after {
      content: "\e904";
      font-family: 'icomoon' !important;
      font-size: 18px;
      color: config.$border-color;
      margin: 0 8px;
      vertical-align: middle;
    }

    &:last-child::after {
      content: "";
      margin: 0;
    }
  }
}

//** Common Footer **********************
.cmnfooter {
  background: config.$nav-bkg;
  box-sizing: border-box;
  bottom: 0;
  font-size: config.$font-small;
  margin-top: auto;
  width: 100%;


  @include mixin.mq-down() {
    padding: 8px 16px;
  }

  .cmnfooter-contents {
    display: flex;
    justify-content: space-between;
    max-width: config.$common-max-width;
    margin: 0 auto;
    width: 80%;

    @include mixin.mq-down() {
      align-items: unset;
      display: inherit;
      margin: 0 auto;
      width: 100%;
    }

    nav {
      padding-right: 32px;
      word-break: break-all;
      width: 80%;

      @include mixin.mq-down() {
        display: none;
      }

      .navi-list {
        overflow: hidden;
        *zoom: 1;
        justify-content: space-between;
        padding: 0;

        @include mixin.mq-down() {
          justify-content: unset;
          width: auto;
          word-break: break-all;
        }

        li {
          display: inline-block;
          padding: 10px 10px 10px 0;
          vertical-align: top;

          @include mixin.mq-down() {
            float: none;
            margin: 0 12px 0 0;
            /*padding: 0;*/
          }

          .title {
            font-weight: bold;
            font-size: config.$font-reset;
          }

          .secondly-list {
            li {
              border-left: 1px solid #ccc;
              display: block;
              float: none;
              padding: 2px 0px 2px 8px;
            }
          }
        }
      }
    }
  }


  .inner-contents,
  .footer-nav {
    overflow: hidden;
    *zoom: 1;
    margin: 0 auto;

  }

  .sign-area {
    border-top: 1px solid #ccc;
    margin: 20px 0;
    padding: 16px 0 0;
  }

  .cmnft-sign {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: config.$common-width;

    @include mixin.mq-down() {
      width: inherit;
    }
  }

  address {
    font-style: normal;

    .corporate-name {
      font-weight: bold;
    }
  }

  a,
  .linkstyle {
    transition: linear .3s;
    color: #666;
    cursor: pointer;
    text-decoration: underline;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;

    &:hover {
      text-decoration: none;
    }
  }
}

//** Common contents **********************
.global-container {
  flex-direction: column;
  max-width: config.$common-max-width;
  margin: 0 auto;
  width: config.$common-width;

  @include mixin.mq-down() {
    width: 100%;
  }
}

.wrapper {
  box-sizing: border-box;
  display: flex;
  margin: 0;
  padding: 24px 0;

  @include mixin.mq-down() {
    display: block;
    margin: 0;
    padding: 0;
    width: auto;
  }
}

.site-gnav {
  /*background: config.$nav-bkg;*/
  box-sizing: content-box;
  flex: 1 0 240px;
  color: hsl(0, 0%, 25%);
  width: 240px;

  @include mixin.mq-down() {
    display: none;
  }

  @include mixin.mq-down() {
    /*メニュー部分*/
    background: config.$sp-navi-background;
    height: 100%;
    left: 0;
    overflow: auto;
    position: fixed;
    /*top: 66px;*/
    width: 100%;

    &>ul {
      bottom: 0px;
      display: block;
      left: 0px;
      margin: auto;
      position: absolute;
      right: 0px;
      top: 70px;

      >li {
        border-bottom: 1px solid #fff;
        color: #fff;
        cursor: pointer;
        margin: 0;
        padding: 16px;
        text-align: left;

        &:first-child {
          border-top: 1px solid #fff;
        }

        .title {
          align-items: center;
          color: #fff;
          display: flex;
          justify-content: space-between;
          margin: 0;
        }

        a {
          color: #fff;
          display: block;
          font-weight: normal;
          text-align: left;
          text-decoration: none;

          &.js-arrow-down:before {
            content: "\e911";
          }

          &:hover {
            opacity: .7;

            @include mixin.mq-down() {
              font-weight: bold;
              background: lighten(config.$sp-navi-background, 50%);
            }
          }
        }

        .icon-triangle-down {
          background: #666;
          color: #fff;
          cursor: pointer;
          margin: 0 0 0 8px;
          padding: 16px;
          position: relative;
          right: 6px;
        }

        .secondly-list {
          display: none;

          li {
            padding: 16px;

            a {
              &:before {
                content: "-";
                padding-right: 8px;
              }
            }
          }
        }
      }
    }

  }

  .login-area {
    overflow: hidden;
    *zoom: 1;
    position: relative;

    @include mixin.mq-down() {
      top: 18px;
      display: flex;
    }

    text-align: center;

    .login-area-btn {
      margin: 0;

      @include mixin.mq-down() {
        box-sizing: border-box;
        margin: 0px auto 18px;
        padding: 0 8px;
        width: 80%;
      }
    }
  }

  .banner {
    margin: 0;
    padding: 0;
    text-align: center;

    @include mixin.mq-down() {
      clear: both;
      display: none;

    }

  }

  .members {
    margin: 0 0 24px;

    @include mixin.mq-down() {
      margin: 0 auto 6px;
      width: config.$common-width;

      @include mixin.mq-down() {
        .btn-basic {
          margin-bottom: 16px;
        }
      }
    }
  }

  .btn-base {
    align-items: center;
    box-shadow: none;
    display: flex;
    font-size: 1.2em;
    min-height: 80px;
    text-align: left;
    text-shadow: none;
    width: 100%;
    word-break: break-word;

    @include mixin.mq-down() {
      justify-content: center;
      line-height: 1.5;
      margin-bottom: 16px;
      position: relative;
    }

    [class^="icon-"] {
      padding: 0;
      position: absolute;
      right: 8px;

      @include mixin.mq-down() {
        position: absolute;
        right: 8px;
      }
    }

    .btn-txt {
      width: 90%;
    }

    li {
      list-style-type: none;
      line-height: 3;
      padding: 0;
      text-align: center;


    }
  }

  .free-txtarea {
    font-size: config.$font-small;

    @include mixin.mq-down() {
      color: #fff;
      display: none;
      padding: 8px;
    }

    .title {
      text-align: center;
    }
  }

  .l-sp .free-txtarea {
    font-size: config.$font-small;
    min-height: 10em;

    @include mixin.mq-down() {
      display: none;
    }
  }

  .wrap-gnav-area {
    margin: 16px 0;

    @include mixin.mq-down() {
      margin: 0;
      padding-bottom: 160px;
    }
  }

  .site-gnav-list {
    border-top: 1px solid config.$border-color;
    font-size: config.$font-reset;
    padding: 0;
    text-align: left;


    .first-level {
      border-bottom: 1px solid config.$border-color;
      line-height: 1.5;

      &.js-navmenu-active {
        background: config.$base-bkg;
        color: #666;
        font-weight: bold;

        span {
          @include mixin.mq-down() {
            background: #666;
            color: #fff;
          }
        }

        .title .nav-title {
          color: config.$link-color;
        }
      }

      .title {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 0 0 0 8px;
        margin: 0;
      }

      .nav-title,
      a {
        line-height: 1.5;
        margin-left: -8px;
        padding: 1em 8px;
        text-align: left;
        text-decoration: none;
        width: 100%;
        word-break: break-all;

        &:hover {
          @include mixin.mq-up() {
            background: #f4f4f4;
          }
        }

        @include mixin.mq-down() {
          color: #fff;
        }
      }

      .icon-lock {
        padding-left: 8px;
      }

      .icon-triangle-down {
        background: #bababa;
        box-sizing: border-box;
        cursor: pointer;
        color: #fff;
        padding: 10px;
        position: relative;
        text-align: center;
        width: 30px;

        @include mixin.mq-down() {
          background: #666;
          box-sizing: border-box;
          width: 30px;
        }


        &:before {
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          margin-top: -8px;
        }
      }

      >.secondly-list {
        background: #fdfdfd;
        display: none;
        font-size: config.$font-small;
        padding: 0;

        @include mixin.mq-down() {
          background: initial;
        }

        li {
          border-top: 1px dotted #ccc;
          font-weight: normal;

          &:last-child {
            border-bottom: none;
          }

          &.js-navmenu-active {
            color: #666;
            font-weight: bold;

            >a {
              background: config.$base-bkg;

              @include mixin.mq-down() {
                background: #666;
                color: #fff;
              }
            }
          }

          a {
            box-sizing: border-box;
            display: block;
            line-height: 1.6;
            margin: 0;
            padding: 8px 8px 8px 2rem;
            text-indent: -2rem;
            text-decoration: none;

            &:before {
              content: "-";
              padding: 8px 8px 8px 1rem;
            }

            &:hover {
              font-weight: bold;

              @include mixin.mq-down() {
                background: #e0e0e0;
                color: #666;
              }
            }
          }
        }
      }
    }
  }

}


main {
  max-width: 1016px;
  padding: 0 0 0 24px;
  width: 100%;

  @include mixin.mq-down() {
    max-width: inherit;
    padding: 0;
    width: auto;
  }

  .main-img {
    img {
      width: 100%;
    }
  }

  .main-img-s {
    img {
      width: 100%;
    }
  }

  .sp-aside-area {
    display: none;

    @include mixin.mq-down() {
      display: block;
      padding: 0 8px;
    }

    .banner {
      overflow: hidden;
      *zoom: 1;
      margin: 0 auto;
      padding-left: 0;

      li {
        @include mixin.mq-down() {
          box-sizing: border-box;
          float: left;
          line-height: 1;
          padding: 4px 0;
          text-align: center;
          width: 50%;

          &:nth-child(even) {
            padding-left: 4px;
          }

          &:nth-child(odd) {
            padding-right: 4px;
            clear: both;
          }
        }

      }
    }

    .free-txtarea {
      @include mixin.mq-down() {
        padding: 8px;
      }
    }

    .table-number {
      @include mixin.mq-down() {
        padding: 8px 16px 0;
      }
    }

  }
}

//** contents wrap**********************
.cont-sp {
  @include mixin.mq-down() {
    padding: 8px 16px 0;
    min-height: 300px;
  }

  p {
    margin: 16px 0;
  }
}

$mgn-numbers: 8,
16,
32;

@each $mgn-num in $mgn-numbers {
  .txtipt-area-#{$mgn-num} {
    margin: #{$mgn-num}px 0;
  }
}

// journal ****************************************
.cover {
  @include mixin.clearfix;
  margin: 16px 0 0 0;

  @include mixin.mq-down() {
    display: block;
    margin: 0;
  }

  .cover-img {
    line-height: 1;
    width: 200px;
    float: left;
    padding: 0 16px 16px 0;

    @include mixin.mq-down() {
      display: block;
      margin: 16px auto 16px;
    }
  }

  .cover-dscpt {
    vertical-align: top;
    padding: 0;

    @include mixin.mq-down() {
      padding: 0;

    }
  }
}

.cover-btn-list {
  display: flex !important;
  margin: 0;
  padding: 0;

  @include mixin.mq-down() {
    margin: 0 auto 32px;
    display: inline;
  }

  li {
    display: block;
    list-style-type: none;
    padding: 8px;

    @include mixin.mq-down() {
      justify-content: center;
      margin: 16px auto;
      padding: 0;
      width: 100%;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    button {
      @include mixin.mq-down() {
        width: 100%;
      }
    }
  }
}

.members-list {
  dt {
    border: 1px solid config.$border-color;
    border-radius: 25px;
    display: table;
    font-size: config.$font-base;
    padding: 0 8px;
  }

  dd {
    display: inline-block;
    font-size: config.$font-base;
    margin: 0;

    &:after {
      color: #ccc;
      content: "/";
      padding: 0 16px;
    }

    &:last-of-type:after {
      content: none;
    }

  }
}

.jurnal-contents {
  border-top: 2px solid config.$border-color;
  display: table;
  padding: 16px 0;
  width: 100%;

  @include mixin.mq-down() {
    display: block;
    padding-left: 0;

  }

  .jurnal-list-area {
    min-width: 180px;
  }

  .ttl-sub {
    margin: 0 0 16px;
  }

  .jurnal-area {
    border-right: 1px solid config.$border-color;
    display: table-cell;
    padding-right: 24px;
    width: 30%;
  }

  .jurnal-dscpt {
    display: table-cell;
    padding-left: 16px;
    vertical-align: top;

    @include mixin.mq-down() {
      display: block;
      padding-left: 0;

    }
  }

  .jurnal-list {
    margin-top: 0;

    .js-title {
      cursor: pointer;
      background: #eee;
      border-bottom: 1px solid #ccc;
      padding: 4px 8px;
      position: relative;

      &.arrow-r:after {
        content: "\e904";
        font-family: 'icomoon' !important;
        font-size: 18px;
        position: absolute;
        right: 8px;
      }

      &.arrow-b:after {
        content: "\e906";
        font-size: 18px;
        position: absolute;
        font-family: 'icomoon' !important;
        right: 8px;
      }
    }

    .js-contents {
      cursor: pointer;
      display: none;

      &.open {
        display: block;
      }

      .vol {
        margin: 0;

        li {
          @include mixin.mq-down() {
            line-height: 3em;
          }
        }
      }
    }
  }

  .jurnal-info {
    border: 1px solid config.$border-color;
    margin: 0 0 8px 0;
    padding: 8px;

    .title {
      margin: 0;
    }

  }

  .author {
    font-size: config.$font-small;

    .conma {
      margin: 4px 0;
    }

    li {
      display: inline;

      &:after {
        content: ",";
      }

      &:last-child:after {
        content: none;
      }
    }
  }

  .doi {
    font-size: config.$font-small;
    margin: 0;

    &:before {
      background: config.$point-color;
      border-radius: 5px;
      color: #fff;
      content: "DOI";
      display: inline-block;
      font-size: config.$font-small;
      line-height: 1.2;
      margin-right: 5px;
      padding: 3px 5px 3px;
      text-align: center;
    }
  }
}

//*** Pagenation **********************************************
.pagenation {
  text-align: center;
  list-style: none;
  margin: 16px auto;
  padding: 0;

  @include mixin.mq-down() {
    margin: 32px auto;
  }

  li {
    display: inline-block;
    margin: 0 4px;

    a,
    span {
      display: inline-block;
      padding: 4px 8px;
    }

    span {
      font-weight: bold;
      font-size: 1.3rem;
    }
  }
}

.pagenation_member {
  text-align: center;
  list-style: none;
  margin: 16px auto;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 4px;
    vertical-align: middle;

    a,
    span {
      display: inline-block;
      padding: 4px 8px;
      text-decoration: none;
    }

    span {
      font-weight: bold;
      font-size: 1.3rem;
    }

    i {
      font-size: 30px;
    }
  }
}

//** Wiziwig wrap**********************
// トップ
.main-free-area {
  &.users-style {
    > :first-child {
      margin-top: 0;
    }
  }
}

// 下層
.user-input-area {
  margin: 16px 0;

  table {
    table-layout: fixed;
  }
}

//** icon-font**********************
.icon-font-space {
  padding-left: 4px;
}
